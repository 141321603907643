// components
import {
	listTypes,
	textValues,
} from '@/components/shared/listingSection/constants';
import LoaderSection from '@/components/shared/listingSection/partials/loaderSection';
// material
import { Box, Typography } from '@mui/material';
// Defaults
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';
// style
import useStyles from './style';
// actions
import { isExternalUrl } from '@/store/actions';

import OtherListSection from '@/components/shared/listingSection/partials/otherListSection';
const BlogListSection = dynamic(
	() => import('@/components/shared/listingSection/partials/blogListSection'),
	{ ssr: false }
);
const UpcomingEventSection = dynamic(
	() => import('@/components/shared/listingSection/partials/eventListSection'),
	{ ssr: false }
);
const WebStoryListSection = dynamic(
	() =>
		import('@/components/shared/listingSection/partials/webStoryListSection'),
	{ ssr: false }
);
const PaginationBox = dynamic(
	() => import('@/components/shared/listingSection/partials/paginationBox'),
	{ ssr: false }
);

export default function ListGroup({
	loading,
	cardList,
	page,
	pageSize,
	total,
	...props
}) {
	const { classes } = useStyles();

	let SectionComponent,
		itemsPerRow = { desktop: 4, largeTablet: 3, tablet: 2 },
		noDataText = textValues.noData;

	switch (props.listType) {
		case listTypes.v1: {
			SectionComponent = UpcomingEventSection;
			noDataText = textValues.noUpcomingEvents;
			break;
		}
		case listTypes.v2: {
			SectionComponent = BlogListSection;
			itemsPerRow = { desktop: 3, largeTablet: 2, tablet: 2 };
			noDataText = textValues.noBlogs;
			break;
		}
		case listTypes.v3: {
			SectionComponent = OtherListSection;
			itemsPerRow = { desktop: 3, largeTablet: 2, tablet: 2 };
			break;
		}
		case listTypes.v4: {
			SectionComponent = WebStoryListSection;
			break;
		}

		default: {
			SectionComponent = UpcomingEventSection;
		}
	}

	/**
	 *  to render single clickable day filter, this week / this month
	 */
	const renderDayFilter = (filterName) => (
		<Typography
			component="span"
			className={`${classes.dayFilter} ${
				props.activeDayFilter === filterName ? classes.activeFilter : ''
			}`}
			onClick={() => props.handleFilterClick(filterName)}
		>
			{filterName}
		</Typography>
	);

	/** box with clickable filters: this week / this month */
	const DayFilterElement = props.showDayFilter && (
		<Box className={classes.dayFilterWrap}>
			{renderDayFilter(textValues.thisWeek)}
			{renderDayFilter(textValues.thisMonth)}
			{renderDayFilter(textValues.allEvents)}
		</Box>
	);

	const PaginationElement = props.paginated && (
		<PaginationBox
			page={page}
			pageSize={pageSize}
			total={total}
			disabled={loading}
			alignment={'end'}
			handlePaginationArrowClick={props.handlePaginationArrowClick}
		/>
	);

	/** row with day filter on left and pagination on right */
	let FilterAndPaginateRow = null;

	if (DayFilterElement || PaginationElement) {
		const className = `${classes.filterSection} ${
			DayFilterElement && PaginationElement ? classes.spaceBetween : ''
		}`;

		FilterAndPaginateRow = (
			<Box className={className}>
				{DayFilterElement}
				{PaginationElement}
			</Box>
		);
	}

	/**
	 *  to render view all link below list cards
	 */
	const renderLinkAndPaginateRow = () => {
		const link = props.link;
		let LinkElement;

		if (!link) {
			return (
				<Box className={classes.linkAndPaginateRow}>{PaginationElement}</Box>
			);
		}

		if (isExternalUrl(link)) {
			LinkElement = (
				<a
					href={link}
					target="_blank"
					rel="noreferrer"
					className={classes.link}
				>
					{textValues.linkText}
				</a>
			);
		} else {
			LinkElement = (
				<Link href={link}>
					<a className={classes.link}>{textValues.linkText}</a>
				</Link>
			);
		}

		return (
			<Box
				className={`${classes.linkAndPaginateRow} ${
					props.paginated ? classes.spaceBetween : classes.linkWrap
				}`}
			>
				{LinkElement}
				{PaginationElement}
			</Box>
		);
	};

	return (
		<>
			{FilterAndPaginateRow}
			<Box className={classes.listBodySection}>
				{loading ? (
					<LoaderSection listCount={pageSize} itemsPerRow={itemsPerRow} />
				) : cardList.length === 0 ? (
					<Typography variant="h6" component="p" className={classes.textCenter}>
						{noDataText}
					</Typography>
				) : (
					<SectionComponent
						cardList={cardList.map((card) => {
							if (card.attributes) {
								card.attributes.id = card.id;
								return card.attributes;
							}
							return card;
						})}
						itemsPerRow={itemsPerRow}
						analyticsProps={props.analyticsProps}
					/>
				)}
			</Box>
			{(props.link || props.paginated) && renderLinkAndPaginateRow()}
		</>
	);
}

ListGroup.propTypes = {
	listType: PropTypes.string.isRequired,
	loading: PropTypes.bool,
	showDayFilter: PropTypes.bool,
	paginated: PropTypes.bool,
	cardList: PropTypes.array,
	activeDayFilter: PropTypes.string,
	handleFilterClick: PropTypes.func.isRequired,
	handlePaginationArrowClick: PropTypes.func.isRequired,
	page: PropTypes.number,
	pageSize: PropTypes.number,
	total: PropTypes.number,
	setPage: PropTypes.func.isRequired,
	link: PropTypes.string,
	analyticsProps: PropTypes.object,
};
ListGroup.defaultProps = {
	listType: listTypes.v1,
	loading: false,
	showDayFilter: false,
	paginated: false,
	analyticsProps: {},
};
